var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"pa-4 grey lighten-4"},[_c('div',{staticClass:"d-flex align-center justify-end mb-4"},[(_vm.solicitacaoCriar && !_vm.isColaboradorIc)?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":"","to":"/treinamento-criar"}},[_vm._v("Nova Solicitação")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center justify-start mb-4"},_vm._l((_vm.dadosDictinary),function(dado){return _c('div',{key:dado.text},[_c('v-chip',{staticClass:"ma-2 font-weight-bold",attrs:{"color":dado.color,"text-color":"#fff","small":""}},[_vm._v(_vm._s(_vm.dados[dado.value])+" - "+_vm._s(dado.text))])],1)}),0),_c('SolicitacoesTabela',{attrs:{"headers":_vm.headers,"solicitacoes":_vm.item,"loading":_vm.loading,"total":_vm.total,"pageText":_vm.pageText,"pageNumber":_vm.page},on:{"handleFilter":_vm.filter,"excluir":_vm.excluirModal,"iniciarAtendimento":function($event){_vm.dialogAssumir = true;
        _vm.itemId = $event.id;
        _vm.itemStatusCarta = $event.statusCarta;},"assumirAtendimento":function($event){_vm.dialogAssumir = true;
        _vm.itemId = $event.id;
        _vm.itemStatusCarta = $event.statusCarta;},"encaminhar":function($event){_vm.dialogEncaminhar = true;
        _vm.itemId = $event;}},scopedSlots:_vm._u([{key:"status",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listStatus,"hide-no-data":"","hide-selected":"","item-text":"name","item-value":"value","placeholder":"Status","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})]},proxy:true},{key:`colaborador.nome`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listColaborador,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Colaborador","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.colaboradorId),callback:function ($$v) {_vm.$set(_vm.formData, "colaboradorId", $$v)},expression:"formData.colaboradorId"}})]},proxy:true},{key:`representante.nome`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listRepresentantes,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Representante","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.representanteId),callback:function ($$v) {_vm.$set(_vm.formData, "representanteId", $$v)},expression:"formData.representanteId"}})]},proxy:true},{key:`produto.nome`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listProdutos,"hide-no-data":"","hide-selected":"","item-text":"nome","item-value":"id","placeholder":"Produtos","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.produtosId),callback:function ($$v) {_vm.$set(_vm.formData, "produtosId", $$v)},expression:"formData.produtosId"}})]},proxy:true},{key:"assunto",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listAssuntos,"hide-no-data":"","hide-selected":"","item-text":"name","item-value":"value","placeholder":"Tema","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.assunto),callback:function ($$v) {_vm.$set(_vm.formData, "assunto", $$v)},expression:"formData.assunto"}})]},proxy:true},{key:"conteudo",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Conteúdo","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.page = 1;
            _vm.buscar(_vm.formData);}},model:{value:(_vm.formData.conteudo),callback:function ($$v) {_vm.$set(_vm.formData, "conteudo", $$v)},expression:"formData.conteudo"}})]},proxy:true},{key:`concorrente.nome`,fn:function(){return [_c('v-text-field',{attrs:{"solo":"","flat":"","dense":"","hide-details":true,"disabled":true}})]},proxy:true},{key:`representante.gd.nome`,fn:function(){return [_c('v-text-field',{attrs:{"solo":"","flat":"","dense":"","hide-details":true,"disabled":true}})]},proxy:true},{key:"createdAt",fn:function(){return [_c('v-menu',{ref:"menuCreatedAt",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","flat":"","dense":"","hide-details":true,"readonly":"","clearable":true,"placeholder":"Aberto em","hint":"MM/DD/YYYY format"},on:{"click:clear":function($event){_vm.page = 1;
                _vm.datasCreatedAt = [];
                _vm.buscar(_vm.formData);}},model:{value:(_vm.datasCreatedAt),callback:function ($$v) {_vm.datasCreatedAt=$$v},expression:"datasCreatedAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuCreatedAt),callback:function ($$v) {_vm.menuCreatedAt=$$v},expression:"menuCreatedAt"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"pt-br","max":_vm.nowDate},model:{value:(_vm.datasCreatedAt),callback:function ($$v) {_vm.datasCreatedAt=$$v},expression:"datasCreatedAt"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuCreatedAt = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuCreatedAt = false;
                _vm.page = 1;
                _vm.buscar(_vm.formData);}}},[_vm._v(" Selecionar ")])],1)],1)]},proxy:true},{key:"concluidoEm",fn:function(){return [_c('v-menu',{ref:"menuCreatedAt",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","flat":"","dense":"","hide-details":true,"readonly":"","clearable":true,"placeholder":"Finalizado em","hint":"MM/DD/YYYY format"},on:{"click:clear":function($event){_vm.page = 1;
                _vm.datasConcluidoEm = [];
                _vm.buscar(_vm.formData);}},model:{value:(_vm.datasConcluidoEm),callback:function ($$v) {_vm.datasConcluidoEm=$$v},expression:"datasConcluidoEm"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuConcluidoEm),callback:function ($$v) {_vm.menuConcluidoEm=$$v},expression:"menuConcluidoEm"}},[_c('v-date-picker',{attrs:{"range":"","no-title":"","scrollable":"","locale":"pt-br","max":_vm.nowDate},model:{value:(_vm.datasConcluidoEm),callback:function ($$v) {_vm.datasConcluidoEm=$$v},expression:"datasConcluidoEm"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuConcluidoEm = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuConcluidoEm = false;
                _vm.page = 1;
                _vm.buscar(_vm.formData);}}},[_vm._v(" Selecionar ")])],1)],1)]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}],null,true)})],1),_c('AlertConfirmation',{attrs:{"dialog":_vm.dialog,"dialogMessage":_vm.dialogMessage},on:{"close":function($event){_vm.dialog = false},"accept":_vm.excluir}}),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":_vm.dialogMessage},on:{"close":function($event){_vm.buscar(_vm.formData);
      _vm.success = false;}}}),_c('AlertConfirmation',{attrs:{"dialog":_vm.dialogAssumir,"thirdButton":true,"dialogMessage":"Gostaria de assumir o atendimento desta solicitação?","icon":"","thirdButtonText":"Ir para carta resposta"},on:{"close":function($event){_vm.dialogAssumir = false},"accept":_vm.assumir,"thirdEvent":_vm.iniciar}}),_c('FormModal',{attrs:{"loading":_vm.loadingEncaminhar,"dialog":_vm.dialogEncaminhar,"dialogMessage":"Encaminhar solicitação"},on:{"close":function($event){_vm.dialogEncaminhar = false},"accept":_vm.encaminhar}},[_c('v-form',{ref:"formEncaminhar",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Comentário","rows":"2","placeholder":"Justificativa para encaminhamento","required":"","rules":_vm.rules.genericRules},model:{value:(_vm.formDataEncaminhar.comentario),callback:function ($$v) {_vm.$set(_vm.formDataEncaminhar, "comentario", $$v)},expression:"formDataEncaminhar.comentario"}})],1)],1),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }